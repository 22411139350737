const clearInlineScriptTag = () => 
{
    if (document.currentScript && document.currentScript.parentElement)
    {
        document.currentScript.parentElement.removeChild(document.currentScript);
    }
}

export function CssClassToggle(sourceSelector: string, cssClassName: string, targetSelector?: string, enabledByDefault = false)
{
    let element = document.querySelector(sourceSelector);
    let target = document.querySelector(targetSelector !== undefined ? targetSelector : sourceSelector);

    if (element)
    {
        element.addEventListener('click', (e) =>
        {
            e.preventDefault();
            target.classList.toggle(cssClassName);
        });

        if (enabledByDefault)
        {
            target.classList.toggle(cssClassName);
        }
    }

    clearInlineScriptTag();
}

